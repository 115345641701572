/* eslint-disable no-undef */
import React from "react"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
 
import dataOne from "@components/pageHome/data"

import data from "@components/pageAgile/data"
import Intro from "@components/pageAgile/components/start"
import BecauseWe from "@components/pageAgile/components/becauseWe"
import ModulesAgile from "@components/pageAgile/components/modulesAgile"
import OurMethodology from "@components/pageAgile/components/ourMethodology"
import SilderCards from "@components/pageAgile/components/silderCards"
import Sentence from "@components/pageAgile/components/sentence"

const StructureCulqui = ({ location }) => {
  
  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  return (
    <div>
      <BannerCookies />
        
      <Header
        path="/nosotros/cultura-agile/"
        location={location}
        windowsWidth={windowsWidth}
      />
      <section className="container">
        <Intro data={data.intro} location={location} />
        <BecauseWe data={data.description} location={location} />
        <ModulesAgile data={data.modules} location={location} />
        <OurMethodology data={data.methodology} location={location} />
        <SilderCards data={data.sildercard} location={location} />
        <Sentence data={data.sentence} location={location} />
        <Footer data={dataOne.footer} location={location} />
        <FooterMobile data={dataOne.footer} location={location} />
      </section>
    </div>
  )
}

export default StructureCulqui
